import { __decorate } from "tslib";
// Watch
import { cdaStore, parceriaCupomHistoricoStore } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ParceriaCupomHistoricoComponent from '@/components/ParceriaCupomHistoricoComponent.vue';
// import VueSwing from 'vue-swing';
import _ from 'lodash';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.cupons = [];
        this.loading = true;
        this.search = '';
        this.selectedCardId = null;
        this.selectPeriodDate = [];
        this.tab = 'tab-cupons';
        this.metricasItems = [];
        this.metricasHeaders = [
            { text: 'Status', value: 'status_id' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
    }
    get cupomItems() {
        return _.orderBy(cdaStore.cupons, 'ordem');
    }
    get parceriaCupomHistorico() {
        return parceriaCupomHistoricoStore.parceriaCuponsHistorico;
    }
    async atualizaPeriodoMetricas(val, oldVal) {
        if (this.loading) {
            return;
        }
        if (_.isEqual(val, oldVal)) {
            return;
        }
        const historico = await parceriaCupomHistoricoStore.getParceriaCupomHistoricoPeriodo({
            inicio: this.selectPeriodDate[0],
            fim: this.selectPeriodDate[1],
        });
        this.metricasItems = _(historico)
            .countBy('status_id')
            .map((item, id) => ({
            status_id: id,
            quantidade: item,
        }))
            .value();
    }
    async onClickCard(item) {
        if (this.selectedCardId == item.id) {
            this.selectedCardId = null;
        }
        else {
            this.selectedCardId = item.id;
        }
    }
    async onChangeStatus(item, createdParceriaHistorico) {
        const value = _.find(this.cupomItems, (v) => v.id === item.id);
        this.$nextTick(() => {
            // @ts-ignore
            value.agendamento = createdParceriaHistorico.agendamento;
            // @ts-ignore
            value.status_id_historico = createdParceriaHistorico.status_id;
            this.cupons = [...this.cupomItems];
            cdaStore.setCupons([...this.cupons]);
        });
    }
    async mounted() {
        this.loading = true;
        await cdaStore.getCupons();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-parcerias-edit', params: { id } });
    }
    routeCupom(id) {
        this.$router.push({ name: 'main-cupons-edit', params: { id } });
    }
};
__decorate([
    Watch('selectPeriodDate')
], List.prototype, "atualizaPeriodoMetricas", null);
List = __decorate([
    Component({
        components: {
            DateFieldComponent,
            PeriodSelectorComponent,
            ParceriaCupomHistoricoComponent,
        },
    })
], List);
export default List;
